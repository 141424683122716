<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button class="mr-3" color="primary" type="filled" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)"></vs-button>
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-refresh-cw" @click="getInitData"></vs-button>
          </div>
        </div>
        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <div class="vx-col w-full sm:w-auto flex justify-between">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="md:mb-0 w-full" placeholder="Cari No Unit" v-model="search"/>
          </div>
        </div>
      </div>
    </vx-card>

    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <!--empty-->
    <div class="w-full mt-10" v-if="isEmpty && !isLoading">
      <div class="flex items-center justify-center">
        <img class="w-64 h-auto m-4 opacity-75" src="@/assets/svg/under_construction.svg" alt="Empty"/>
      </div>
      <p class="text-center opacity-75">Tidak ada data.</p>
    </div>

    <div class="vx-row">
      <div v-for="item in listUnit" :key="item.id" class="vx-col mb-3 w-full sm:w-1/3 cursor-pointer">
        <vx-card @click="goToListItemPekerjaan(item.id)">
          <div class="flex">
            <div class="w-full">
              <h5>Unit {{ item.no_unit }}</h5>
              <span class="text-sm" :class="item.total_bobot_completed < 100 ? 'text-primary' : 'text-success'">Bobot Tercapai: {{ item.total_bobot_completed }}%</span>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressUnitRepository from '@/repositories/proyek/progress-unit-repository'
import _ from 'lodash'

export default {
  name: 'ProgressUnitListUnit',
  props: {},
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      search: null,
      units: [],
      spk: {
        pekerjaan: []
      }
    }
  },
  computed: {
    listUnit () {
      const items = this.units

      // if search
      const search = this.search
      if (search != null) {
        return _.filter(items, (item) => {
          return item.no_unit.toLowerCase().includes(search)
        })
      }

      return items
    },
    isEmpty () {
      return this.listUnit.length < 1
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idSpk = this.$route.params.idSpk
      ProgressUnitRepository.getSpkUnits(idSpk)
        .then(response => {
          this.units = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    goToListItemPekerjaan (idPropertiUnit) {
      const idSpk = this.$route.params.idSpk
      this.$router.push({ name: 'proyek.progressUnit.asKontraktor.itemPekerjaanUnit', params: { idSpk: idSpk, idPropertiUnit: idPropertiUnit } })
    }
  }
}
</script>
