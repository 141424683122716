import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/progressUnit'

export default {
  getPropertiUnit (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/propertiUnit${queryParams}`)
  },

  getSpk (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/spk${queryParams}`)
  },

  getSpkUnits (idSpk) {
    return httpClient.get(`${endpoint}/spk/${idSpk}/units`)
  },

  showProgressPekerjaan (idProgressUnit) {
    return httpClient.get(`${endpoint}/${idProgressUnit}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  }
}
